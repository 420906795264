import consumer from "./consumer"
consumer.subscriptions.create("ChatChannel", {
  received(data) {
    let notification = data
    increaseUnreadCount($(`#ChatUnreadCount-${notification.chat_id}`))
    increaseUnreadCount($(".ChatUnreadCount-All"))
    Paige.refreshSession()

    console.log("update for",`#chat-${notification.chat_id}`)

    if($(`#chat-${notification.chat_id}`).is(":visible")){
      $.get(window.location + ".js").then( (x) => {} )
    }
  }
});

function increaseUnreadCount($el){
    if($el.length <= 0) { return }
    let currentCount = parseInt($el.first().text(), 10)
    let nextCount = currentCount + 1
    $el.text(nextCount)
    $el.attr("data-count", nextCount.toString())
}

